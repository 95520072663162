<template>
  <v-dialog v-model="state" width="400px">
    <v-card>
      <ValidationObserver
        tag="div"
        class="d-flex flex-row flex-grow-1"
        v-slot="{ handleSubmit }"
      >
        <form
          class="d-flex flex-column flex-grow-1"
          @submit.prevent="handleSubmit(addNewRevenueType)"
        >
          <v-card-text>
            <ValidationProvider
              tag="div"
              rules="required"
              v-slot="{ errors }"
              class="d-flex flex-column flex-grow-1"
            >
              <v-text-field
                autofocus
                :error-messages="errors[0]"
                label="New Mode Of Payment or Revenue Type"
                prepend-icon="credit_card"
                class="font font-weight-medium font-size-md"
                v-model="name"
                hint="Enter mode of payment eg. credit card, shell card, union card etc."
              />
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="close" text>
              <span
                class="font text-capitalize font-weight-medium font-size-md"
              >
                Cancel
              </span>
            </v-btn>
            <v-btn
              type="submit"
              :loading="loading"
              :disabled="loading"
              color="primary"
            >
              <span
                class="font text-capitalize font-weight-medium font-size-md"
              >
                Save
              </span>
            </v-btn>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Input field is required"
});
import { mapGetters } from "vuex";
export default {
  name: "AddNewPrice",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    state: { type: Boolean, default: false },
    loading: {
      type: Boolean,
      default: false
    },
    currency: {
      type: Array,
      default: () => []
    },
    availablePrices: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      resetFormState: "getResetFormState"
    })
  },
  data: () => ({
    name: ""
  }),
  watch: {
    resetFormState(value) {
      if (value) {
        this.name = null;
      }
      setTimeout(() => {
        this.$store.dispatch("resetFormState", false, { root: true });
      }, 2000);
    }
  },
  methods: {
    addNewRevenueType() {
      this.$emit("addNewRevenueType", { name: this.name });
    },
    close() {
      this.$emit("close", "generate");
    },
    fireSnackBarMessage() {
      this.$store.commit("UPDATE_SNACKBAR", true, { root: true });
      this.$store.commit("UPDATE_MESSAGE", "Invalid value provided", {
        root: true
      });
      this.$store.commit("UPDATE_STATUS", "red", { root: true });

      setTimeout(() => {
        this.$store.commit("UPDATE_SNACKBAR", false, { root: true });
      }, 2000);
    }
  }
};
</script>
